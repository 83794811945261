@import '../../../../styles/breakpoints';
@import '../../../../styles/grid-config';

.container {
    margin-left: auto;
    margin-right: auto;

    max-width: $grid-max-width;
    padding-left: margin('_');
    padding-right: margin('_');
    width: 100%;

    @each $breakpoint-key, $breakpoint in $breakpoints {
        @if map-has-key($grid-margin, $breakpoint-key) {
            @include screen-min-width($breakpoint-key) {
                padding-left: margin($breakpoint-key);
                padding-right: margin($breakpoint-key);
            }
        }
    }
}
