@use 'sass:math';
@import '../../../../styles/font-sizes';
@import '../../../../styles/theme';
@import '../../../../styles/dimensions';
@import '../../../../styles/screens';

$letter-spacing: -0.2px;

.wrapper {
    display: block;

    @include screen-min-width('medium') {
        max-width: $control-width-medium;
    }
}

.wrapper.full-width {
    @include screen-min-width('medium') {
        max-width: unset;
    }
}

.wrapper:not(:last-of-type) {
    padding-bottom: $gap-medium;
}

.input,
.adornment {
    @include text-menu-header;
    letter-spacing: $letter-spacing;
}

.adornment:not(:first-child) {
    margin-left: $gap-x-small;
}

.container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.input {
    border-bottom: $border-slim solid theme-var('input-border');
    border-radius: 0;
    caret-color: theme-var('primary');
    color: theme-var('text');
    flex: 1 0 100%;
    height: 100%;
    overflow: hidden;
    transition: all 0.2s ease;
}

.input::placeholder {
    color: theme-var('input-border');
}

.input:hover {
    border-bottom-color: theme-var('input-focus-border');
}

.input:focus {
    border-bottom-color: theme-var('primary');
}

.input:focus::placeholder {
    color: theme-var('input-focus-border');
}

/* stylelint-disable selector-no-qualifying-type */
select.input {
    /* add down arrow icon to right side of the select for ios */
    appearance: none;
    appearance: none;
    background: none;
    background: url('https://step.com/assets/icons/select-down-arrow.png')
        no-repeat right center;
    background-size: 0.75em;
    border: 0;
    border-bottom: $border-slim solid theme-var('input-border');
}
/* stylelint-enable selector-no-qualifying-type */

.label {
    @include font-size-small;
    color: theme-var('input-focus-border');
    letter-spacing: $letter-spacing;
    padding-bottom: $gap-x-small;
}

.label,
.error {
    flex: 0 0 100%;
    font-size: var(--font-size-small);
}

.input-with-error,
.label-with-error,
.error {
    color: theme-var('negative');
}

.error {
    @include font-size-x-small;
    padding-top: math.div($gap-xx-small, 2);
    white-space: nowrap;
}

@include screen-max-width('medium') {
    .input {
        width: 100%;
    }
}
