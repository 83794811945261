@import '../../styles/utils';

.button {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
}

.disabled {
    @include disabled;
    pointer-events: none;
}
