@import '../../styles/grid-config';
@import '../../styles/dimensions';
@import '../../styles/theme';

$icon-width: 16px;

.button {
    align-items: center;
    background-color: transparent;
    border-radius: $max-border-radius;
    cursor: pointer;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: $button-padding;
    transition: 350ms ease-in-out;
    transition-property: width, background-color;
    width: space(12);
}

.button-active {
    background-color: var(--step-white);
    color: var(--step-black);
    min-width: 0%;
    width: space(12);
}

.open-icon {
    min-width: $icon-width;
}

.close-icon {
    min-width: $icon-width;
}
