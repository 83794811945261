/* stylelint-disable */
@import './breakpoints';

#CybotCookiebotDialog {
    @include screen-max-width('lg') {
        left: 50% !important;
        max-height: 50vh !important;
        bottom: 1% !important;
        transform: translate(-50%) !important;
        border: 1px solid var(--step-before-midnight);
    }
}

#CybotCookiebotDialogBodyButtonAccept {
    border: none !important;
    border-radius: 3em !important;
    padding: 1em 2em !important;
}

#CybotCookiebotDialogBodyButtonDecline {
    border: none !important;
    background: none !important;
    color: var(--step-white) !important;
    margin-bottom: 0 !important;
}

#CybotCookiebotDialogPoweredByText,
#CybotCookiebotDialogPoweredbyCybot {
    display: none !important;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer,
#CybotCookiebotDialogHeader {
    border-color: var(--step-before-midnight) !important;
}
