@import '../../../styles/font-sizes';
@import '../../../styles/dimensions';
@import '../../../styles/breakpoints';

.team-banner {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    padding: $gap-x-small;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 7;
    @include font-size-small;

    @include screen-max-width('md') {
        top: var(--header-height);
    }

    span {
        flex: 1;
    }

    .close-button {
        height: $icon-size-x-small;
        width: $icon-size-x-small;
    }
}
