@import '../../styles/utils';
@import '../../styles/screens';

.picture {
    display: flex;
}

.image {
    max-width: 100%;
    overflow: hidden;
    transition: background 0.2s linear;
}

.cover {
    object-fit: cover;
}

.contain {
    object-fit: contain;
}
