@import '../../../styles/breakpoints';
@import '../../../styles/dimensions';
@import '../../../styles/theme';
@import './dimensions';

.wrapper {
    backdrop-filter: blur($blur-amount);
    background: theme-var('header-background');
    height: var(--header-bar-height);
    left: 50%;
    margin-top: var(--header-top);
    position: fixed;
    transform: translateX(-50%);
    width: 100%;
    z-index: var(--header-z-index);

    @include screen-max-width('md') {
        padding: 0;
    }
}

.header {
    position: relative;
    width: 100%;

    @media print {
        display: none;
    }
}

.header::before {
    content: '';
    height: var(--header-bar-height);
    position: absolute;
    width: 100%;

    @include screen-max-width('md') {
        border-radius: 0;
    }
}

.inner {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: space(3) space(5);
    position: relative;
    width: 100%;
}

.right {
    align-items: center;
    display: flex;
    gap: space(6);
}

.list {
    flex-direction: row;
    justify-self: flex-end;

    @include screen-min-width('md') {
        display: flex;
    }
}

.link {
    align-items: center;
    display: flex;
    padding: 0 space(2);

    transition: color 200ms ease;

    &:hover,
    &--highlighted {
        color: var(--theme-primary);
    }

    @include screen-min-width('lg') {
        padding: 0 space(6);
    }
}

.logo-img {
    display: block;
    height: $logo-size;
}

.buttons {
    align-items: center;
    column-gap: space(2);
    display: flex;
    flex-direction: row;
}
