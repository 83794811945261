@import '../../styles/theme';
@import '../../styles/dimensions';

.tooltip {
    display: inline-block;
    position: relative;
}

.content {
    background-color: var(--step-transparent-dark-grey);
    border-radius: $box-border-radius;
    box-shadow: 0 0 $box-border-radius var(--theme-box-shadow);
    left: 50%;
    min-width: $tooltip-min-width;
    padding: $gap-x-small;
    position: absolute;
    text-align: center;
    top: 115%;
    transform: translateX(-50%);
    z-index: var(--tooltip-z-index);

    &::after {
        border-color: transparent transparent var(--step-transparent-dark-grey);
        border-style: solid;
        border-width: calc($tooltip-arrow-size / 2);
        content: '';
        left: 50%;
        margin-left: -calc($tooltip-arrow-size / 2);
        position: absolute;
        top: -$tooltip-arrow-size;
    }
}
