@import '../../../styles/theme';
@import '../../../styles/dimensions';
@import '../../../styles/font-sizes';

.button {
    color: theme-var('primary');
    composes: core from './core.module.scss';

    &:active {
        color: theme-var('primary-down');
    }
}

.medium {
    @include font-size-default;
}

.small {
    @include font-size-x-small;
}

.playing {
    composes: playing from './core.module.scss';
}

.complete {
    composes: complete from './core.module.scss';
}
