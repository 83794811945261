@import '../../../styles/theme';
@import '../../../styles/dimensions';

.button {
    background: theme-var('primary');
    color: theme-var('inverse-text');
    composes: block from './core.module.scss';
    transition: background 1s linear;

    &:active {
        background: theme-var('primary-down');
    }
}

.medium {
    composes: medium from './core.module.scss';
}

.small {
    composes: small from './core.module.scss';
}

.playing {
    composes: playing from './core.module.scss';
}

.complete {
    composes: complete from './core.module.scss';
}
