@import '../../../styles/theme';
@import '../../../styles/dimensions';

.button {
    background: palette-var('transparent');
    border: $border-medium solid theme-var('primary');
    color: theme-var('primary');
    composes: block from './core.module.scss';
    transition: color, border-color 1s linear;

    &:active {
        border-color: theme-var('primary-down');
        color: theme-var('primary-down');
    }
}

.medium {
    composes: medium from './core.module.scss';
}

.small {
    composes: small from './core.module.scss';
}

.playing {
    composes: playing from './core.module.scss';
}

.complete {
    composes: complete from './core.module.scss';
}
