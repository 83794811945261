@import '../../../../styles/breakpoints';
@import '../../../../styles/grid-config';

.flex {
    display: flex;
}

/**
 * Default flex-direction classes are split from responsive classes so that
 * responsive classes can be defined below their default couterparts.
 * For example, ".flex-row--md" must be defined below ".flex-col" to ensure that
 * ".flex-row--md" takes precedence.
 */

$flex-directions: 'row', 'row-reverse', 'column', 'reverse';

// default classes
@each $flex-direction in $flex-directions {
    .flex-#{$flex-direction} {
        flex-direction: #{$flex-direction};
    }
}

// responsive classes
@each $flex-direction in $flex-directions {
    @include responsive-class('flex-#{$flex-direction}') {
        flex-direction: #{$flex-direction};
    }
}

/**
 * Default order classes are split from responsive classes so that
 * responsive classes can be defined below their default couterparts.
 * For example, ".order-5--md" must be defined below ".order-2" to ensure that
 * ".order-5--md" takes precedence.
 */

// default classes
@for $i from 0 through $grid-columns {
    .order-#{$i} {
        order: #{$i};
    }
}

// responsive classes
@each $breakpoint-key, $breakpoint in $breakpoints {
    @for $i from 0 through $grid-columns {
        @include breakpoint-class('order-#{$i}', $breakpoint-key) {
            order: #{$i};
        }
    }
}

/**
 * Default align classes are split from responsive classes so that
 * responsive classes can be defined below their default couterparts.
 * For example, ".align-start--md" must be defined below ".align-center" to ensure that
 * ".align-start--md" takes precedence.
 */

$align-types: 'flex-start', 'center', 'flex-end', 'baseline', 'stretch';

// default classes
@each $align-type in $align-types {
    .align-#{$align-type} {
        align-items: #{$align-type};

        &.column {
            align-self: #{$align-type};
        }
    }
}

// responsive classes
@each $align-type in $align-types {
    @include responsive-class('align-#{$align-type}') {
        align-items: #{$align-type};

        &.column {
            align-self: #{$align-type};
        }
    }
}

/**
 * Default content classes are split from responsive classes so that
 * responsive classes can be defined below their default couterparts.
 * For example, ".content-start--md" must be defined below ".content-center" to ensure that
 * ".content-start--md" takes precedence.
 */

$content-types: 'flex-start', 'center', 'flex-end', 'space-around',
    'space-between';

// default classes
@each $content-type in $content-types {
    .content-#{$content-type} {
        align-content: #{$content-type};
    }
}

// responsive classes
@each $content-type in $content-types {
    @include responsive-class('content-#{$content-type}') {
        align-content: #{$content-type};
    }
}

/**
 * Default justfy classes are split from responsive classes so that
 * responsive classes can be defined below their default couterparts.
 * For example, ".justfy-start--md" must be defined below ".justfy-center" to ensure that
 * ".justfy-start--md" takes precedence.
 */

$justify-types: 'flex-start', 'center', 'flex-end', 'space-around',
    'space-between', 'space-evenly';

// default classes
@each $justify-type in $justify-types {
    .justify-#{$justify-type} {
        justify-content: #{$justify-type};
    }
}

// responsive classes
@each $justify-type in $justify-types {
    @include responsive-class('justify-#{$justify-type}') {
        justify-content: #{$justify-type};
    }
}

/**
 * Default wrap classes are split from responsive classes so that
 * responsive classes can be defined below their default couterparts.
 * For example, ".wrap-nowrap--md" must be defined below ".wrap-wrap" to ensure that
 * ".wrap-nowrap--md" takes precedence.
 */

$wrap-types: 'nowrap', 'wrap', 'wrap-reverse';

// default classes
@each $wrap-type in $wrap-types {
    .wrap-#{$wrap-type} {
        flex-wrap: #{$wrap-type};
    }
}

// responsive classes
@each $wrap-type in $wrap-types {
    @include responsive-class('wrap-#{$wrap-type}') {
        flex-wrap: #{$wrap-type};
    }
}
