$palette-colors: (
    'angry': #ea3b28,
    'before-midnight': #49495d,
    'almost-black': #17171b,
    'right-before-black': #292931,
    'black': #000,
    'black-v2': #111,
    'dark-grey': #222,
    'dark-gray': #222,
    'background-gray': #e7e7e7,
    'blueish-grey': rgba(66, 75, 90, 0.1),
    'coal-mine-canary': #fcd411,
    'coal-mine-canary-down': #fdc411,
    'coal-mine-canary-weak': rgba(#fcd411, 0.2),
    'cold-steel': #afb4c2,
    'cold-steel-medium': rgba(#afb4c2, 0.5),
    'cold-steel-opaque': rgba(#afb4c2, 0.8),
    'cold-steel-weak': rgba(#afb4c2, 0.2),
    'cyanora': #3cd0f5,
    'cyanora-down': #16b4dc,
    'cyanora-weak': rgba(#16b4dc, 0.2),
    'eleven-forty-five': #2f2f40,
    'future-fuscia-legacy': #f6629d,
    'future-fuscia': #f66299,
    'future-fuscia-down': darken(#f66299, 10%),
    'future-fuscia-weak': rgba(#f66299, 0.2),
    'midnight': #222234,
    'midnight-haze': #19191f,
    'midnight-thirty': #12121b,
    'midnight-weak': rgba(#222234, 0.1),
    'off-white': #f6f7fa,
    'off-white-weak': rgba(#f6f7fa, 0.2),
    'right-before-midnight': #363649,
    'transparent': rgba(255, 255, 255, 0),
    'transparent-midnight': rgba(55, 55, 94, 0.6),
    'transparent-midnight-weak': rgba(55, 55, 94, 0.15),
    'transparent-dark-grey': rgba(34, 34, 34, 1),
    'transparent-black-v2': rgba(17, 17, 17, 0.6),
    'transparent-black': rgba(255, 255, 255, 0.2),
    'weak-grey': rgba(34, 34, 52, 0.5),
    'white': #fff,

    'springtime': #64da90,
    'springtime-down': #64da90,
    'springtime-weak': #64da90,
    'amethyst': #c772fd,
    'amethyst-down': #c772fd,
    'amethyst-weak': #c772fd,
    'apricot': #ff8f28,
    'apricot-down': #ff8f28,
    'apricot-weak': #64da90,

    // gradient colors
    'blue-jeans': #63b6f8,
    'coral': #f98351,
    'coral-down': #f98e7e,
    'heliotrope-violet': #c574fd,
    'lemon-sherbet': #fcd411,
    'pink-flamingo': #f969ed,
);

$gradients: (
    'linear-gradient-cyanora-to-future-fuscia':
        linear-gradient(
            90deg,
            palette-var('cyanora') 0%,
            palette-var('future-fuscia') 100%
        ),
    'vertical-gradient-cyanora-to-future-fuscia':
        linear-gradient(
            to bottom,
            palette-var('cyanora') 0%,
            palette-var('future-fuscia') 100%
        ),
    'linear-gradient-overlay':
        linear-gradient(
            0deg,
            palette-var('weak-grey'),
            palette-var('weak-grey')
        ),
    'sharp-gradient-tricolor':
        linear-gradient(
            to left,
            palette-var('future-fuscia') 0% 32%,
            palette-var('cyanora') 33% 66%,
            palette-var('coal-mine-canary') 67% 99%
        ),
    'vertical-gradient-cyanora-to-violet':
        linear-gradient(
            to bottom,
            palette-var('cyanora') 0%,
            palette-var('heliotrope-violet') 100%
        ),
    'diagonal-gradient-midnight-haze-to-black':
        linear-gradient(
            to bottom right,
            palette-var('midnight-haze') 40%,
            palette-var('black') 100%
        ),
);

@mixin set-palette-variables {
    @each $key, $value in $palette-colors {
        --step-#{$key}: #{$value};
    }

    @each $key, $value in $gradients {
        --step-#{$key}: #{$value};
    }
}

@function palette($color) {
    @if map-has-key($gradients, $color) {
        @return map-get($gradients, $color);
    }
    @return map-get($palette-colors, $color);
}

@function palette-var($color) {
    @return var(--step-#{$color});
}
