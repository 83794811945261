@import '../../styles/dimensions';

.socials {
    column-gap: space(9);
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: space(2);
}

.icon {
    width: space(5);
}
