$grid-columns: 12;

$grid-max-width: 1440px;

$grid-gutter: (
    'lg': 20px,
    // medium breakpoint and larger
    // (extention example)
    // 'sm': 18px, // small breakpoint and larger
    '_': 15px,
    // default
);

$grid-margin: (
    'lg': 55px,
    'md': 40px,
    // medium breakpoint and larger
    // (extention example)
    // 'sm': 18px, // small breakpoint and larger
    '_': 20px,
    // default
);

@function gutter($type) {
    @return map-get($map: $grid-gutter, $key: $type);
}

@function margin($type) {
    @return map-get($map: $grid-margin, $key: $type);
}
