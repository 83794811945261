@use 'sass:math';
@import '../../../styles/colors';
@import '../../../styles/dimensions';
@import '../../../styles/font-sizes';
@import '../../../styles/palette';
@import '../../../styles/screens';
@import '../../../styles/theme';

.core {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: center;
    letter-spacing: -0.18px;
    line-height: 1;
    position: relative;
    width: 100%;

    &:hover,
    &:focus {
        @include hoverme;
    }
}

.block {
    box-shadow: 0 math.div($border-medium, 2) 0 theme-var('box-shadow');
    composes: core;
    min-width: $control-min-width;
}

.medium {
    @include font-size-default;
    border-radius: $control-border-radius-medium;
    height: $control-height-medium;
    padding: 0 $control-border-radius-small;

    @include screen-min-width('medium') {
        max-width: $control-width-medium;
    }

    @include screen-max-width('medium') {
        margin: auto;
    }
}

.small {
    @include font-size-small;
    border-radius: $control-border-radius-small;
    height: $control-height-small;
    padding: 0 $control-border-radius-small;
}

.playing,
.complete {
    background: palette-var('transparent');
    box-shadow: unset;
    content: '';
    pointer-events: none;
}

@media print {
    .core {
        display: none;
    }
}
