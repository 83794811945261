@import '../../styles/dimensions';
@import '../../styles/screens';
@import '../../styles/font-sizes';
@import '../../styles/utils';

@include text-align-variability;
@include scroll-anchor;

.heading {
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.heading::first-letter {
    text-transform: uppercase;
}

.heading:empty:not(.skeleton) {
    display: none;
}

.heading-1,
.heading-2 {
    @include font-size-x-large;
}

.heading-1,
.heading-2,
.heading-3 {
    &:not(:last-child) {
        margin-bottom: $gap-small;
    }
}

.skeleton-1,
.skeleton-2 {
    height: $line-height-x-large;
    margin: $gap-x-small 0;
}

.heading-3 {
    @include font-size-large;
}

.skeleton-3 {
    margin: 6px 0;
    min-height: var(--font-size-large);
}

.heading-4 {
    @include font-size-medium;
}
