@import '../../../styles/dimensions';

.links-row {
    padding-top: space(12);
}

.category {
    margin-bottom: space(6);
}

.links {
    list-style: none;
    margin: 0;
    padding: 0;
}

.link-item {
    margin-bottom: space(6);
}
