@import '../../../styles/breakpoints';
@import '../../../styles/colors';
@import '../../../styles/utils';
@import '../../../styles/theme';
@import '../../../styles/dimensions';
@import '../../../styles/dimensions';
@import '../header/dimensions';

.content {
    align-items: center;
    color: theme-var('text');
    color: #fff;
    cursor: default;
    display: flex;
    position: relative;
}

/** Increase the clickable/hoverable zone to combat the gap */
.content::after {
    content: '';
    display: block;
    height: 30px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 0;
}

.dropdown {
    background: theme-var('header-background');
    border-radius: 12px;
    box-shadow: 0 -1px 0 0 rgba(159, 159, 222, 0.18);
    margin: 0;
    padding: 0;
    position: fixed;
    top: var(--header-bar-height);
}

.dropdown::before {
    backdrop-filter: blur($blur-amount);
    content: '';
    height: calc(100% + 24px);
    position: absolute;
    width: 100%;
}

.inner {
    position: relative;
}

.list {
    font-weight: normal;
    list-style: none;
    min-width: 143px;
    outline: none;
    padding: 12px;
    z-index: 3;
}

.item {
    display: inline-block;
    padding: 12px;
    width: 100%;
}

.item-highlighted {
    color: theme-var('primary');
}

.item-selected {
    color: var(--theme-primary);
    font-weight: bold;
}

.trigger {
    align-items: center;
    display: flex;
    padding: 0 space(2);
    transition: color 200ms ease;

    &:hover,
    &--highlighted {
        color: var(--theme-primary);
    }

    @include screen-min-width('lg') {
        padding: space(2) space(6);
    }
}

.trigger-text {
    margin-right: $gap-xx-small;

    @include screen-min-width('lg') {
        margin-right: $gap-x-small;
    }
}

/** This places an invisible line with the length of bold label.
 * Fixes layout shift issues. */
.trigger-text::before,
.item::before {
    color: transparent;
    content: attr(data-label);
    display: block;
    font-weight: bold;
    height: 1px;
    overflow: hidden;
    visibility: hidden;
}

.trigger-open,
.trigger:hover {
    .trigger-text {
        color: theme-var('primary');
    }

    path {
        fill: theme-var('primary');
    }
}
