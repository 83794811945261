@use 'sass:math';
@import '../../../../styles/breakpoints';
@import '../../../../styles/grid-config';

.column {
    flex: 1;
    max-width: 100%;

    padding-left: math.div(gutter('_'), 2);
    padding-right: math.div(gutter('_'), 2);
    position: relative;

    @each $breakpoint-key, $breakpoint in $breakpoints {
        @if map-has-key($grid-margin, $breakpoint-key) {
            @include screen-min-width($breakpoint-key) {
                padding-left: math.div(gutter($breakpoint-key), 2);
                padding-right: math.div(gutter($breakpoint-key), 2);
            }
        }
    }
}

/**
 * Default classes are split from responsive classes so that responsive classes
 * can be defined below their default couterparts.
 * For example, ".span-3--md" must be defined below ".span-4" to ensure that
 * ".span-3--md" takes precedence.
 */

// default classes
@for $i from 0 through $grid-columns {
    .span-#{$i} {
        flex: 0 0 percentage(math.div(1, $grid-columns) * $i);
        max-width: percentage(math.div(1, $grid-columns) * $i);
    }

    .offset-#{$i} {
        margin-left: percentage(math.div(1, $grid-columns)) * $i;
    }

    .push-#{$i} {
        left: percentage(math.div(1, $grid-columns)) * $i;
    }

    .pull-#{$i} {
        right: percentage(math.div(1, $grid-columns)) * $i;
    }

    .order-#{$i} {
        order: $i;
    }
}

// responsive classes
@each $breakpoint-key, $breakpoint in $breakpoints {
    @for $i from 0 through $grid-columns {
        @include breakpoint-class('span-#{$i}', $breakpoint-key) {
            flex: 0 0 percentage(math.div(1, $grid-columns) * $i);
            max-width: percentage(math.div(1, $grid-columns) * $i);
        }

        @include breakpoint-class('offset-#{$i}', $breakpoint-key) {
            margin-left: percentage(math.div(1, $grid-columns)) * $i;
        }

        @include breakpoint-class('push-#{$i}', $breakpoint-key) {
            left: percentage(math.div(1, $grid-columns)) * $i;
        }

        @include breakpoint-class('pull-#{$i}', $breakpoint-key) {
            right: percentage(math.div(1, $grid-columns)) * $i;
        }

        @include breakpoint-class('order-#{$i}', $breakpoint-key) {
            order: $i;
        }
    }
}
