@import '../../../styles/colors';

.animated {
    transform-origin: center;
    transition: transform 0.2s ease-out;
}

.up {
    transform: rotate(180deg);
}

.left {
    transform: rotate(90deg);
}

.down {
    transform: rotate(0deg);
}

.right {
    transform: rotate(-90deg);
}

@include fill-color-variability;
