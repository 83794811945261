@use 'sass:math';
@import '../../styles/dimensions';
@import '../../styles/screens';

.list {
    margin: 0;
    padding-left: 1em;
}

.list > .list {
    margin-top: $gap-small;
}

.item {
    line-height: 28px;
    margin-bottom: math.div($gap-x-small, 2);
}

.with-custom-icons {
    list-style: none;
    padding: 0 $gap-x-small;
}

.item-with-icon {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.icon {
    flex: 0 0 #{$list-icon-size};
    margin-right: $list-icon-gap;
}

@include screen-min-width('medium') {
    .with-custom-icons {
        padding: 0;
    }
}
