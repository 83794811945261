.animation {
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.animation-playing,
.animation-complete {
    opacity: 1;
}
