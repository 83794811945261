/* stylelint-disable selector-no-qualifying-type */
@import './colors';
@import './screens';
@import './dimensions';
@import './font-sizes';
@import './breakpoints';
@import './easings';
@import './cookie-overrides';

:root {
    @include set-color-variables;
    @include set-palette-variables;
    @include set-theme-variables('default');
    @include set-theme-variables(
        'cyanora'
    ); // set default theme for legacy pages
    @include theme-classes;

    --font-size-x-small: 12px;
    --font-size-small: 14px;
    --font-size-default: $font-size-default;
    --font-size-medium: 18px;
    --font-size-large: 28px;
    --font-size-x-large: 36px;

    /** Try to include all global z-indexes here if you can, so we can track their usage.
     * Not gonna be the end of the world if you don't.
     * Just know that this is how you end up with z-index: 10000001 :|
     *
     * P.S. don't add every single z-index here.
     * Just those that you have to write for nodes to appear on top of the elements below. */
    --highlight-z-index: 1;
    --main-z-index: 2;
    --token-canvas-z-index: 3;
    --footer-z-index: 4;
    --nav-overlay-z-index: 5;
    --header-z-index: 6;
    --sidebar-z-index: 7;
    --portal-z-index: 8;
    --fixed-section-z-index: 8;
    --dev-action-z-index: 9;
    --snackbar-z-index: 9;
    --tooltip-z-index: 9;

    /** 
    * Layout
    */
    --viewport-height: calc(100vh - var(--header-height));

    --header-top: #{$mobile-header-top}px;
    --header-bar-height: #{$header-bar-height}px;
    --header-height: #{$mobile-header-height}px;
    --header-banner-height: #{$mobile-header-banner-height}px;

    @include screen-min-width('md') {
        --header-top: #{$desktop-header-top}px;
        --header-height: #{$desktop-header-height}px;
        --header-banner-height: #{$desktop-header-banner-height}px;
    }

    --content-height: var(--viewport-height);

    --stair-size: #{$mobile-stair-size};

    @include screen-min-width('md') {
        --stair-size: #{$desktop-stair-size};
    }
}

html {
    box-sizing: border-box;
    font-family: Inter Variable, Montserrat, Helvetica, sans-serif;
    font-size: var(--font-size-default);
}

body {
    background-color: theme-var('background');
    color: theme-var('text');
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100%;
    min-width: breakpoint('x-small');

    @include set-theme-variables('default');
}

body[mode='black'] {
    background-color: palette-var('black-v2');
}

body main::after {
    background-color: palette-var('black-v2');
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

body[mode='black'] main::after {
    background: palette-var('black-v2');
}

main {
    background-color: transparent;
    position: relative;
    z-index: var(--main-z-index);
}

/* stylelint-disable */
/* Restyling page progress element from nprogress module.
 * This code is U-U-gly. Don't do this in your kitchen. */
#nprogress {
    .bar {
        top: 0;
        background: palette-var('sharp-gradient-tricolor');
    }

    .peg {
        box-shadow: unset;
    }
}
/* stylelint-enable */

/** Apply a natural box layout model to all elements, but allowing components to change */
*,
*::before,
*::after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: theme-var('tap-highlight');
}

/** Reset control styles */
a,
button,
input,
figure,
label {
    background-color: unset;
    border: unset;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
    outline: none;
    padding: 0;
    text-decoration: none;
}

strong {
    font-weight: bold;
}

p,
ul,
li {
    font-size: var(--font-size-default);
    line-height: $line-height-medium;
}

.stairs {
    &--top-right {
        clip-path: polygon(
            0% 0%,
            calc(100% - var(--stair-size) * 2) 0,
            calc(100% - var(--stair-size) * 2) var(--stair-size),
            calc(100% - var(--stair-size)) var(--stair-size),
            calc(100% - var(--stair-size)) calc(var(--stair-size) * 2),
            100% calc(2 * var(--stair-size)),
            100% 100%,
            0% 100%
        );
    }

    &--top-left {
        clip-path: polygon(
            0% calc(var(--stair-size) * 2),
            var(--stair-size) calc(var(--stair-size) * 2),
            var(--stair-size) var(--stair-size),
            calc(var(--stair-size) * 2) var(--stair-size),
            calc(var(--stair-size) * 2) 0%,
            100% 0%,
            100% 100%,
            0% 100%
        );
    }

    &--bottom-right {
        clip-path: polygon(
            0% 0%,
            100% 0%,
            100% calc(100% - var(--stair-size) * 2),
            calc(100% - var(--stair-size)) calc(100% - var(--stair-size) * 2),
            calc(100% - var(--stair-size)) calc(100% - var(--stair-size)),
            calc(100% - var(--stair-size) * 2) calc(100% - var(--stair-size)),
            calc(100% - var(--stair-size) * 2) 100%,
            0% 100%
        );
    }

    &--bottom-left {
        clip-path: polygon(
            0% 0%,
            100% 0%,
            100% 100%,
            calc(var(--stair-size) * 2) 100%,
            calc(var(--stair-size) * 2) calc(100% - var(--stair-size)),
            var(--stair-size) calc(100% - var(--stair-size)),
            var(--stair-size) calc(100% - var(--stair-size) * 2),
            0% calc(100% - var(--stair-size) * 2)
        );
    }
}

// Override default styles for symbols
.symbol {
    display: inline-block;
    font-size: 24px;
    height: 12px;
    line-height: 1px;
    position: relative;
    top: 4px;

    &.subscript {
        top: 0;
    }

    &.superscript {
        left: 8px;
        top: 8px;
    }

    &.magnifyingGlass {
        left: -6px;
        top: 8px;
    }
}

sup {
    top: -0.4em;
}
