@import '../../../styles/colors';
@import '../../../styles/dimensions';
@import '../../../styles/screens';
@import '../../../styles/utils';
@import '../../../styles/font-sizes';

.control {
    height: var(--height);
    position: relative;
}

.download-app {
    zoom: 0.8;
}

.form {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: var(--form-height);
    width: var(--form-width);
}

.medium {
    --form-width: #{$get-app-form-width-medium};
    --form-height: #{$get-app-form-height-medium};
    --height: #{$control-height-medium};
    --width: #{$control-width-medium};
}

.small {
    --form-width: #{$get-app-form-width-small};
    --form-height: #{$get-app-form-height-small};
    --width: #{$control-width-small};
    --height: #{$control-height-small};
    align-self: end;
}

.field,
.submit {
    bottom: 0;
    position: absolute;
    top: 0;
    z-index: 1;
}

.submit {
    align-items: center;
    display: flex;
    flex-direction: row;
    min-width: 120px;
}

.success {
    color: theme-var('positive');
    font-weight: bold;
    padding: 0 $control-border-radius-medium;
}

.why,
.error {
    margin: #{$gap-small} auto 0;
    max-width: var(--form-width);
    @include font-size-small;
}

.why {
    color: theme-var('secondary-text');
}

.error {
    color: theme-var('negative');
}

@include screen-max-width('medium') {
    .control {
        margin: auto;
    }
}

.field-small::before {
    // this gradient is to make content overlap more presentable
    border-bottom-left-radius: $control-border-radius-medium;
    border-top-left-radius: $control-border-radius-medium;
    bottom: -30%;
    content: '';
    position: absolute;
    top: -30%;
}

.field-small::before {
    right: 35%;
    width: 100%;
}

.form.placement--header {
    height: auto;
    width: auto;
}

.form.placement--header:not([data-expanded='true']) button {
    width: auto;
}
