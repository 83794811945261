@import '../../styles/colors';
@import '../../styles/screens';
@import '../../styles/dimensions';

.button {
    display: block;
    height: $header-logo-height;
}

.button svg {
    border: 1px solid var(--app-button-color, var(--step-white));
    fill: var(--app-button-color, var(--step-white));
}

.button:hover,
.button:focus {
    @include hoverme;
}

.button:active svg {
    border-color: pressme('step-cyanora');
    fill: pressme('step-cyanora');
}

@include screen-max-width('medium') {
    .googleplay-on-ios,
    .appstore-on-android {
        display: none;
    }
}
