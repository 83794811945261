@import '../../styles/dimensions';
@import '../../styles/colors';

.wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    width: 100%;
}

.main {
    display: flex;
    flex-direction: column;
    min-height: var(--viewport-height);
}

@media print {
    .wrapper {
        display: block;
    }
}

.with-header {
    padding-top: var(--header-height);
}
