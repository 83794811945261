@import '../../../styles/breakpoints';
@import '../../../styles/dimensions';
@import '../../../components/text/type-styles.module';

.container {
    align-items: center;
    border-bottom: 1px solid var(--step-before-midnight);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 325px;
    padding-bottom: $gap-x-large;
    padding-top: $gap-x-large;

    p {
        @include heading-md;
        letter-spacing: -1.04px;
        margin: $gap-medium 0;
        text-align: center;
        text-transform: uppercase;
    }
}

.downloadApp {
    @include screen-max-width('xs') {
        zoom: 0.8;
    }
}
