@import '../../../styles/breakpoints';
@import '../../../styles/dimensions';
@import '../../../styles/theme';
@import './dimensions';

.footer {
    background: theme-var('footer-background');
    min-height: space(75);
    position: relative;
    z-index: var(--footer-z-index);

    @media print {
        display: none;
    }
}

.inner {
    position: relative;
}

.logo {
    bottom: 0;
    position: absolute;
    right: 0;

    width: $logo-width-desktop;

    @include screen-max-width('md') {
        width: $logo-width-mobile;
    }
}
