.mobile-store-icon {
    border-radius: 10px;
    outline: none;
}

@each $val in ('white', 'midnight') {
    .step-#{$val} {
        border: 2px solid var(--step-#{$val});
        fill: var(--step-#{$val});
    }
}
