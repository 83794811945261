@import '../../styles/breakpoints';

.desktop {
    @include screen-max-width('md') {
        display: none !important;
    }
}

.mobile {
    width: 100%;

    @include screen-min-width('md') {
        display: none !important;
    }
}
