@import '../../styles/dimensions';
@import '../../styles/colors';
@import '../../styles/utils';

.spinner {
    @include absolute-center;
    stroke-width: $spinner-thickness;
}

@each $key, $value in $spinner-sizes {
    .spinner-#{$key} {
        height: $value;
        width: $value;
    }
}

.inner {
    @include absolute-center;
    animation: spin 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
}

.circle {
    animation: dash 1.5s ease-in-out infinite;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
}

@each $key, $value in $colors {
    .circle-#{$key} {
        stroke: var(--#{$key});
    }
}

.circle-multi {
    animation: dash 2s linear infinite, multicolor 6s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 100, 200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 100, 200;
        stroke-dashoffset: -124;
    }
}

@keyframes multicolor {
    0%,
    25% {
        stroke: var(--step-future-fuscia);
    }

    33%,
    54% {
        stroke: var(--step-cyanora);
    }

    66%,
    100% {
        stroke: var(--step-coal-mine-canary);
    }
}
