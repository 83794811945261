@import '../../../../styles/font-sizes';
@import '../../../../styles/colors';
@import '../../../../styles/dimensions';
@import '../../../../styles/screens';

.input {
    border-radius: var(--border-radius);
    color: theme-var('color');
    font-variant-numeric: tabular-nums;
    height: 100%;
    overflow: hidden;
    padding: 0 var(--border-radius) 0 $gap-x-small;
    width: 100%;
}

.input-with-adornment {
    padding-left: calc(var(--adornment-width) + #{$gap-x-small});
}

.input-with-error {
    color: theme-var('negative');
}

.input-with-error::placeholder {
    color: theme-var('negative');
}

.container-medium {
    --width: #{$control-width-medium};
    --height: #{$control-height-medium};
    --border-radius: #{$control-border-radius-medium};
    --adornment-width: #{$control-prefix-length-medium};

    @include font-size-default;
    max-width: $control-width-medium;
}

.container-small {
    --width: #{$control-width-small};
    --height: #{$control-height-small};
    --border-radius: #{$control-border-radius-small};
    --adornment-width: #{$control-prefix-length-small};

    @include font-size-small;
    line-height: 1;
    min-width: 75px;
}

.input-small {
    // hello Safari my old friend
    font-size: var(--font-size-small);
    line-height: 1.5;
}

.container {
    //border: $border-medium solid #{theme-var('white')};
    background: theme-var('transparent-background');
    border-radius: var(--border-radius);
    display: block;
    height: var(--height);
    position: relative;
    transition: border-color 0.5s linear;
}

.container:hover,
.container:focus {
    .input::placeholder {
        color: theme-var('white');
    }
}

.container:focus-within {
    --input-accent-color: theme-var('primary-down');
}

.container-with-error {
    position: relative;
}

.label {
    padding: $gap-small 0 1px $gap-x-small;
}

.label,
.error {
    @include font-size-small;
}

.label-with-error,
.error {
    color: theme-var('negative');
}

.error {
    background: theme-var('white');
    left: 0;
    margin: $gap-small auto;
    position: absolute;
    right: 0;
    top: 100%;
    width: var(--width);
}

.adornment {
    align-items: center;
    //background: theme-var('transparent-background');
    border-bottom-left-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
    bottom: 0;
    color: theme-var('white');
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: var(--adornment-width);
    z-index: 1;
}
