@import '../../styles/dimensions';
@import '../../styles/screens';
@import '../../styles/font-sizes';

.section {
    margin: $gap-large 0;
}

.apps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: $gap-large;
}

.light {
    --app-button-color: var(--step-midnight);
}

.dark {
    --app-button-color: var(--step-white);
}

.app:not(:last-child) {
    margin-right: $gap-small;
}
