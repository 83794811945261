@import '../../styles/breakpoints';
@import '../../styles/colors';

// default color classes
.default {
    color: var(--theme-text);
}

.success {
    color: theme-var('success');
}

.negative {
    color: theme-var('negative');
}

.dim {
    color: var(--theme-text-dim);
}

.primary {
    color: var(--theme-primary);

    &::selection {
        color: var(--theme-secondary);
    }
}

.secondary {
    color: var(--theme-secondary);
}

.tertiary {
    color: var(--theme-tertiary);
}

.background {
    color: var(--theme-background);
}

@each $brand-color-key, $brand-color in $brand {
    .#{$brand-color-key} {
        color: $brand-color;
    }
}

// responsive color classes
@include responsive-class('default') {
    color: var(--theme-text);
}

@include responsive-class('dim') {
    color: var(--theme-text-dim);
}

@include responsive-class('primary') {
    color: var(--theme-primary);
}

@include responsive-class('secondary') {
    color: var(--theme-secondary);
}

@include responsive-class('tertiary') {
    color: var(--theme-tertiary);
}

@include responsive-class('background') {
    color: var(--theme-background);
}

@each $brand-color-key, $brand-color in $brand {
    @include responsive-class('#{$brand-color-key}') {
        color: $brand-color;
    }
}
