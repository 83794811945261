@import '../../../styles/dimensions';

$blur-amount: 72px;
$blur-amount-small: 40px;
$logo-size: 42px;

$nav-max-height: 700px;

$mobile-nav-top: space(6);
$mobile-nav-bottom: space(10);
$close-icon-width: 16px;
