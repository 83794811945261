@import '../../styles/dimensions';
@import '../../styles/colors';
@import '../../styles/screens';
@import '../../styles/font-sizes';

@include text-color-variability;

.paragraph {
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
}

.medium {
    font-size: var(--font-size-default);
    line-height: 28px;
}

.small {
    @include font-size-small;
}

.paragraph:empty:not(.skeleton) {
    display: none;
}

.skeleton {
    margin-top: 10px;
    min-height: var(--font-size-default);
}

@each $side in ('top', 'bottom') {
    .pad-#{$side} {
        padding-#{$side}: 1em;
    }
}
