@import '../../styles/font-sizes';
@import '../../styles/colors';

.default {
    @include font-size-default;
    color: theme-var('primary');
}

.default:active {
    color: pressme('step-cyanora');
}

.inner {
    display: inline-block;
}
