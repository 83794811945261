@use 'sass:math';
@import '../../../../styles/breakpoints';
@import '../../../../styles/grid-config';

.row {
    flex-wrap: wrap;
    margin-left: math.div(gutter('_'), -2);
    margin-right: math.div(gutter('_'), -2);

    @each $breakpoint-key, $breakpoint in $breakpoints {
        @if map-has-key($grid-margin, $breakpoint-key) {
            @include screen-min-width($breakpoint-key) {
                margin-left: math.div(gutter($breakpoint-key), -2);
                margin-right: math.div(gutter($breakpoint-key), -2);
            }
        }
    }
}
